import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { calculateSpacing } from "../../../../utils/getContainerClasses";

const GenericSimpleText = ({ fields, rendering, sitecoreContext }) => {
  let useBlocks = sitecoreContext.route.fields["use blocks"]?.value;
  let inverseColor = fields["inverse color"]?.value ? "inverse-color" : "";

  return (
    <div
      className={`
      component 
      comp-title
      ${inverseColor}
      ${fields["css classes"]?.value}
      ${calculateSpacing(fields)}
    `}
    >
      {useBlocks && (
        <div className="bg-light p-4" style={{ border: "1px solid black" }}>
          generic title: {rendering.dataSource}{" "}
        </div>
      )}

      {!useBlocks && <RichText field={fields.text} />}
    </div>
  );
};

export default withSitecoreContext()(GenericSimpleText);
